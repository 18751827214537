import React from 'react'

import styled from 'styled-components'
import {Row} from 'react-bootstrap'

import Layout from '../layouts/layout'

import banerjpg from '../images/headerPhotos/kontakt.jpg'

const contact = ({data}) => {
  return(
    <Layout
      headerPhoto={<img src={banerjpg} alt="kontakt"/>}
    >
      <Row className="text-center">
        <Span>
            Oops, coś poszło nie tak.<br/>
            Strona, której szukasz nie istnieje.
        </Span>
      </Row>
    </Layout>
  )
}

export default contact

const Span = styled.span`
padding:10% 0;
width:100%;
font-size: 1.5em;
text-align:center;
`;

